$background:#f0f0f8;
$color:#242e4c;

$dark-theme:#212741;



/*Color variables*/
$primary:#525ce5; 
$secondary:#9c52fd;
$pink:#ec407a;
$teal:#2bcbba;
$cyan:#17a2b8;
$purple:#661fd6;
$success:#56d094;
$warning:#ffa70b;
$danger:#fb5454;
$info:#2f89f5;
$orange:#f86300;
$dark:#101215;
$indigo:#6574cd;
$gray:#868e96;
$white:#fff;
$black:#000;



/*border variables*/
$border:rgba(234, 238, 251, 0.8);

/*shadow variables*/
$shadow:-8px 12px 18px 0 rgba(14, 17, 28, 0.01);




	

