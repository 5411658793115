@import "../scss/variables";

@charset "UTF-8";

.app-sidebar .mCSB_draggerContainer {
  right: -11px !important;
}

@media (min-width: 768px) {
  .app.sidenav-toggled {
    .app-content {
      margin-left: 0;
    }

    .app-sidebar {
      left: 250px;
    }

    .app-sidebar__overlay {
      visibility: hidden;
    }
  }

  .app-sidebar {
    padding-top: 62px;
  }

  .side-header {
    width: 250px;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 99;
  }

  .app.sidebar-mini.sidenav-toggled .side-header {
    width: 70px !important;
  }

  .app-sidebar__toggle {
    margin-left: 16.5rem;
  }
}

@media (max-width: 767px) {
  .app {
    overflow-x: hidden;

    .app-sidebar {
      left: -250px;
    }

    .app-sidebar__overlay {
      visibility: hidden;
    }

    &.sidenav-toggled {
      .app-content {
        margin-left: 0;
      }

      .app-sidebar {
        left: 0;
      }

      .app-sidebar__overlay {
        visibility: visible;
      }
    }

    &.sidebar-mini.sidebar-gone.sidenav-toggled .app-sidebar {
      left: 0;
    }
  }
}

.app-content {
  min-height: calc(100vh - 50px);
  margin-bottom: 0 !important;
  -webkit-transition: margin-left 0.3s ease;
  -o-transition: margin-left 0.3s ease;
  transition: margin-left 0.3s ease;
  overflow: hidden;

  .side-app {
    padding: 0px 30px 0 30px;
  }
}

.footer .container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .app-content {
    margin-left: 250px;
  }
}

@media (max-width: 767px) {
  .app-content {
    min-width: 100%;
  }
}

@media (max-width: 480px) {}

@media print {
  .app-content {
    margin: 0;
    padding: 0;
    background-color: $white;
  }
}

/*----- Componant: Top Navigation Bar ----- */

.app-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 999;
  padding-right: 15px;
  padding: 5px 0;
   box-shadow:5px 7px 26px -5px rgba(14, 17, 28, 0.02);

  /*box-shadow: $shadow*/;
  border-bottom: 1px solid rgba(227, 231, 241, 0.8);
  background: $white;
}

@media print {
  .app-header {
    display: none;
  }
}

.app-header__logo {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  color: $white;
  text-align: center;
  font-family: 'Niconne';
  padding: 0 15px;
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;

  &:focus, &:hover {
    text-decoration: none;
  }
}

@media (min-width: 768px) {
  .app-header__logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: block;
    width: 230px;
  }
}

.app-sidebar__toggle {
  color: #677ca7;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  z-index: 1;
  margin-top: 8px;

  svg {
    fill: #677ca7;
    margin-top: 6px;
  }

  &:focus, &:hover {
    text-decoration: none;
  }
}

/*@media (max-width: 360px) {
	.app-sidebar__toggle {
		line-height: 2;
	}
}*/
@media (max-width: 767px) {
  .app-sidebar__toggle {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    margin-left: 10px;
  }
}

/*.app-sidebar__toggle:before {
	content: "\e907";
	transition: all .25s;
	font-size: 21px;
	font-family: 'feather';
}*/

.app-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  color: #8a98ac;
  width: 250px;
  max-height: 100%;
  z-index: 1000;
  background: $white !important;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
  -webkit-transition: left 0.3s ease, width 0.3s ease;
  -o-transition: left 0.3s ease, width 0.3s ease;
  transition: left 0.3s ease, width 0.3s ease;
  border-right: 1px solid rgba(227, 231, 241, 0.8);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }
}

@media print {
  .app-sidebar {
    display: none;
  }
}

@media (max-width: 767px) {
  .app-sidebar__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
}

.app-sidebar__user {
  .dropdown-menu {
    top: 10px !important;
  }

  img {
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.1);
    padding: 2px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 5px 5px 0px rgba(7, 16, 31, 0.4);
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #a8a8a8;
  width: 100%;
  padding: 15px 0 15px;
  display: inline-block;
  border-bottom: 1px solid rgba(215, 227, 243, 0.5);
  background: linear-gradient(to right, rgba(238, 83, 124, 0.8), rgba(188, 107, 221, 0.8)), url(../../images/media/media1.jpg);
}

.app-sidebar__user-avatar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 15px;
}

.app-sidebar__user-name {
  &.text-sm {
    font-size: 12px;
    font-weight: 400;
  }

  font-size: 17px;
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 600;
  color: #e5e9ec;
  font-size: 15px;
  margin-top: 5px !important;
}

.app-sidebar__user-designation {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 600;
  color: #e5e9ec;
  font-size: 15px;
  margin-top: 5px !important;
}

.side-menu {
  margin-bottom: 0;
}

.side-menu__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: 500;
  -webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  -o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  transition: border-left-color 0.3s ease, background-color 0.3s ease;
  color: #606d94;

  &.active, &:hover, &:focus {
    text-decoration: none;
    color: #8499c4;
  }

  &.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
    color: #8499c4;
  }
}

.slide-item {
  &.active, &:hover, &:focus {
    text-decoration: none;
    color: #b5c1d2;
  }
}

.slide-menu a.active {
  color: #7083ab;
}

.slide-item {
  &.active, &:hover, &:focus {
    text-decoration: none;
    color: #7083ab;
  }
}

.slide-menu li {
  position: relative;
}

.side-menu .slide {
  position: relative;
  padding: 0px;
}

.slide.is-expanded a {
  /*color: #fcfeff;*/
  text-decoration: none;
}

.side-menu .side-menu__icon {
  fill: #677ca7;
  height: 22px;
  width: 22px;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  margin-right: 10px;
}

.side-menu__icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.side-menu__label {
  white-space: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.slide.is-expanded {
  .slide-menu {
    max-height: 100vh;
    -webkit-transition: max-height 0.3s ease;
    -o-transition: max-height 0.3s ease;
    transition: max-height 0.3s ease;
  }

  .angle {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.slide-menu {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease;
  -o-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
  padding: 0;
  font-size: .8rem !important;
  padding-left: 25px;
}

.slide.is-expanded .slide-menu {
  position: relative;
  margin: 12px 0 12px;
}

.side-menu .slide .side-menu__item {
  margin: 2px 0;
}

.slide-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 7px;
  font-size: 13px;
  color:$color;
  margin-left: 18px;
}

.slide-menu li:before {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  border: 1px solid #aab3c3;
  left: 0px;
  top: 13px;
  display: block;
  border-radius: 50%;
}

.slide-item .icon {
  margin-right: 5px;
}

.angle {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

@media (min-width: 768px) {
  .sidebar-mini.sidenav-toggled {
    .app-sidebar__user-name, .app-sidebar__user-designation, .angle, .app-sidebar__user-name, .user-notification, .app-sidebar__user-name, .user-info {
      display: none;
    }

    .sidebar-mini.sidenav-toggled.user-notification::before {
      background: transparent;
      display: none;
    }

    .app-sidebar__user-name, .avatar-xl {
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      font-size: 1rem;
      margin-bottom: 0px !important;
    }

    .app-sidebar__user {
      .user-pic {
        margin-bottom: 0;
      }

      margin-top: 15px;
    }

    .side-menu {
      margin-top: 15px;
    }

    .app-sidebar__user-avatar {
      width: 25px;
      height: 25px;
    }

    .side-menu li .side-menu__item.active:before {
      display: none;
    }

    .app-sidebar__user {
      padding: 12px 0px 12px 0;
      margin-bottom: 0px;
      border-bottom: 1px solid rgba(225, 225, 225, 0.05);
    }

    .profile-img {
      top: 0px;
      right: 19px;
    }

    .app-content {
      margin-left: 70px;
    }

    .app-sidebar {
      left: 0;
      width: 70px;
      overflow: hidden;
    }

    .side-menu__label {
      display: block;
      position: absolute;
      top: 0;
      opacity: 0;
    }

    .slide .side-menu__label {
      border-bottom-right-radius: 0;
    }

    .slide-menu {
      position: absolute;
      left: 90px;
      min-width: 180px;
      opacity: 0;
      border-bottom-right-radius: 4px;
      z-index: 9;
      visibility: hidden;
      -webkit-transition: visibility 0.3s ease;
      -o-transition: visibility 0.3s ease;
      transition: visibility 0.3s ease;
      -webkit-box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
    }
  }

  .app.sidebar-mini.sidenav-toggled {
    .side-menu__item {
      display: block;
      padding: 10px 0;
      margin: 0 auto;
      text-align: center;
      border-left: 0;
    }

    .side-menu_label {
      display: block;
      font-size: 12px;
    }
  }

  .sidebar-mini.sidenav-toggled .app-sidebar__user .avatar-md {
    margin: 0 auto;
  }

  .app.sidebar-mini.sidenav-toggled .nav-badge {
    position: absolute;
    top: 8px;
    right: 28px;
    padding: 0.2rem 0.4rem;
    font-size: 11px;
  }

  .sidebar-mini.sidenav-toggled {
    .app-sidebar__toggle {
      margin-left: 5rem;
    }

    .side-header .light-logo {
      display: block;
    }
  }

  .app.sidebar-mini {
    &.sidenav-toggled .side-menu .side-menu__icon {
      margin-right: 0;
      margin-left: 0;
    }

    &.sidenav-toggled-open {
      .app-sidebar {
        left: 0;
        width: 250px;
      }

      .side-header {
        width: 250px !important;

        .header-brand-img {
          &.light-logo1 {
            display: block !important;
          }

          &.toggle-logo {
            display: none !important;
          }
        }
      }

      .side-menu__item {
        display: flex;
        align-items: center;
        padding:8px 14px;
        margin: 2px 0;
      }

      .side-menu__icon {
        margin-right: 10px !important;
      }

      .slide-menu {
        max-height: 0;
        overflow: hidden;
        -webkit-transition: max-height 0.3s ease;
        -o-transition: max-height 0.3s ease;
        transition: max-height 0.3s ease;
        padding: 0;
        font-size: .8rem !important;
        padding-left: 25px;
        position: relative;
        left: 0;
        box-shadow: none;
        visibility: visible;
        opacity: inherit;
      }

      .slide.is-expanded .slide-menu {
        max-height: 100vh;
        -webkit-transition: max-height 0.3s ease;
        -o-transition: max-height 0.3s ease;
        transition: max-height 0.3s ease;
      }

      .side-menu__label {
        white-space: nowrap;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        opacity: 1;
        display: flex;
        position: initial;
      }

      .angle, .side-menu h3 {
        display: block;
      }
    }
  }

  /*sidenav-toggled-open*/
}

.app.sidebar-mini .side-menu_label {
  display: none;
}

.sidenav-toggled .app-sidebar__user .avatar-md {
  line-height: 2rem;
  font-size: 1rem;
}

.app-sidebar {
  .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: rgba(0, 0, 0, 0.05);
  }

  .mCSB_scrollTools {
    .mCSB_dragger .mCSB_dragger_bar, .mCSB_draggerRail {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.sidenav-toggled .main-wrapper .side-menu .side-menu__item {
  .nav-badge {
    position: absolute;
    top: 5px;
    left: 57px;
    display: block !important;
    padding: 3px 5px !important;
  }

  .nav-badge1 {
    display: none;
  }
}

.nav-badge {
  border-radius: 30px;
  padding: 0.4em 0.6em;
  font-size: 12px;
}

.user-info {
  .text-dark {
    color: $white !important;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0.2rem !important;
  }

  .text-muted {
    color: #cfd8ea !important;
  }
}

.side-header {
  .header-brand1 {
    text-align: center;
    display: table;
    margin: 0 auto;
  }

  .header-brand-img.desktop-logo {
    max-height: 2.5rem;
    text-align: center;
    display: none;
    margin-right: 0;
  }
}

.sidebar-mini.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo {
  display: none;
}

.side-header .header-brand-img {
  &.desktop-logo {
    display: none;
  }

  &.light-logo1 {
    display: block;
  }
}

.sidebar-mini.sidenav-toggled {
  .app-sidebar .side-header .header-brand-img {
    &.light-logo1 {
      display: none;
    }

    &.toggle-logo {
      display: none;
      margin-right: 0;
    }
  }

  .sidebar-navs {
    display: none;
  }
}

.side-header {
  display: flex;
  border-bottom: 1px solid transparent;
  padding: 10px;
  -webkit-transition: left 0.3s ease, width 0.3s ease;
  -o-transition: left 0.3s ease, width 0.3s ease;
  transition: left 0.3s ease, width 0.3s ease;
  border-right: 1px solid rgba(227, 231, 241, 0.8);
  background: $white;
  border-bottom:1px solid rgba(227, 231, 241, 0.8);
}

.sidebar-mini.sidenav-toggled {
  .app-sidebar__toggle {
    display: block;
  }

  .mobile-header .app-sidebar__toggle {
    margin: 0;
    margin-top: 5px;
  }
}

.user-pic {
  margin-bottom: 0.8rem;
}

.sidebar-navs a {
  background: #25335f;
  border: 1px solid rgba(255, 255, 255, 0.04) !important;
  color: #68798b !important;
  border-radius: 5px;
  padding: 0.8rem !important;
}

.app-sidebar .sidebar-navs {
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
}

.sidebar-navs .nav li:last-child a {
  margin-right: 0 !important;
}

.side-menu h3 {
  color: #242e4c;
  text-transform: uppercase;
  letter-spacing: .5px;
  font-weight: bold;
  font-size: 11px;
  margin-bottom: 0.2rem;
  margin-top: 1.1rem;
  font-weight: 700;
  position: relative;
  padding: 7px 15px;

  &:before {
    content: '\e999';
    font-family: feather !important;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    top: 8px;
    margin-right: 5px;
  }
}

.sidebar-mini.sidenav-toggled .side-menu h3 {
  display: none;
}

.side-menu__item {
  &.active, &:hover, &:focus {
    background: #f1f3fd;
    box-shadow: 0;
    border-radius: 0px;
  }
}

.app.sidebar-mini {
  .footer {
    padding: 1.25rem 1.25rem 1.25rem 250px;
  }

  &.sidenav-toggled .footer {
    padding: 1.25rem 1.25rem 1.25rem 70px;
  }
}


@media (max-width: 767px) {
	.app.sidebar-mini .footer {
		padding: 1.25rem !important;
	}
}

.side-header .header-brand-img.toggle-logo{
	display:none;
}
.app.sidebar-mini.sidenav-toggled-open .side-header .header-brand-img.light-logo{
	display:none;
}
.side-menu__item.active:after{
	content: '';
    width: 3px;
    height: 38px;
    background: #525ce5;
    position: absolute;
    right: 0;
	top:0;
}
.banner .w-95{
	 width:250px !important
  }